body {
  margin: 0;
  font-family: TTNorms-Medium, TTNorms-MediumItalic
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant-ligatures: no-common-ligatures;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'TTNorms-Medium';
  src: local('TTNorms-Medium'), url(../src/assets/fonts/TTNorms-Medium.otf) format('truetype');
}

@font-face {
  font-family: 'TTNorms-MediumItalic';
  src: local('TTNorms-MediumItalic'), url(../src/assets/fonts/TTNorms-Medium.otf) format('truetype');
}
