.App {
  text-align: center;
  height: 100vh;
  max-width: 100%;
}
.container{
  max-width: 100vw;
  padding-right: 0;
}
.col{
  padding: 0;
}
.btn-primary, .btn-primary:active, .btn-primary:visited, .btn-primary:focus {
  background-color: #00bf71 !important;
  border-color: initial !important;
  color: #ffffff;
  box-shadow: none;
  border: none;
}
.btn-primary:hover{
  background-color: #008f55 !important;
}
.reject, .reject:active, .reject:visited, .reject:focus {
  background-color: #DADADA !important;
  border-color: initial !important;
  color: #787878;
  box-shadow: none;
  border: none;
}
.reject:hover {
  background-color: #a5a5a5 !important;
  color: #787878;
}
.centered-buttons{
  margin: auto;
}
.send-sms-button{
  display: block;
  margin: auto;
}
.margin-top{
  margin-top: 2.5rem;
}
.action-button{
  min-width: 100% !important;
  min-height: 50px;
  border-radius: 4px;
}
.action-button-small{
  min-width: 40% !important;
  min-height: 50px;
  border-radius: 4px;
}
.modal-header {
  background-color: #C4C4C4;
}

.list-group-item-light {
  background-color: #E5E5E5;
}

.list-group-item-light.list-group-item-action:focus, .list-group-item-light.list-group-item-action:hover {
  background-color: #C4C4C4;
}

.row {
  margin-right: 0;
}

@media (max-width: 414px) {
  body {
    font-size: 0.8rem;
  }
  .btn-primary {
    font-size: 0.8rem;
  }
  .reject {
    font-size: 0.8rem;
  }
}
