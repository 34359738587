.App-header{
    position: absolute;
    width: 100%;
    z-index: 2;
}
nav {
    display: flex;
    align-items: center;
    background-color: #ffffff !important;
    height: 4rem;
}

.nav-container {
    width: 50%;
}

.logo {
    width: 18%;
    height: auto;
    margin-left: 3rem;
    float: left;
}

#logo_text {
    margin-bottom: 0;
    color: #434342;
    margin-right: 3rem;
    font-size: 0.9rem;
    font-weight: 500;
    text-align: right;
}

@media (max-width: 1024px) {
    .logo {
        width: 30%;
    }
}

@media (max-width: 768px) {
    .logo {
        width: 40%;
    }
}

@media (max-width: 414px) {
    .logo {
        width: 70%;
        margin-left: 1rem;
    }
    #logo_text {
        margin-right: 1rem;
        font-size: 0.8rem;
    }
}
