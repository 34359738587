.process-finished-container {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #434343;
  background: url(../../assets/images/vidsigner_process_finished.png) no-repeat center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: -moz-flex;
  display: flex;
  flex-flow: column nowrap;
  -moz-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  align-items: center;
  align-content: center;
}

.process-finished-text-container {
  position: absolute;
  top: 47%;
  left: 0;
  right: 0;
  text-align: center;
}

#process-finished-title {
  color: #ffffff;
  font-size: 2rem;
}

.process-finished-subtitle {
  font-size: 1.7rem;
  color: #ffffff;
}

@media (max-width: 1024px) {

}

@media (max-width: 414px) {
  #process-finished-title {
    font-size: 2rem;
  }
  .process-finished-subtitle {
    font-size: 1.4rem;
  }
}

@media (max-width: 375px) {
  #process-finished-title {
    font-size: 1.8rem;
  }
  .process-finished-subtitle {
    font-size: 1.2rem;
  }
}

@media (max-width: 320px) {
  #process-finished-title {
    font-size: 1.6rem;
  }
  .process-finished-subtitle {
    font-size: 1rem;
  }
}
