.documents-container {
  height: 100vh;
  width: 100%;
  background-color: #f6f6f6;
}

.documents-list-container {
  background-color: #f6f6f6;
  display: block;
  min-height: 85vh;
  padding: 1.25em;
  height: 100%;
  overflow-y: auto;
}

.documents-list-header {

}

.documents-list-body {
  max-width: 1160px;
  max-height: 65vh;
  box-sizing: border-box;
  overflow-y: scroll;
  margin: 0 auto;
  padding-bottom: 3vh;
  margin-top: 50px;
}

.toast-container {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.no-pending-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-docs-icon {
  width: 6rem;
}

#no-docs-title {
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
  color: #434343;
}

#no-docs-subtitle {
  margin-top: 1rem;
  font-size: 1.2rem;
  color: #434343;
  font-weight: 300;
  text-align: center;
  margin-bottom: 0;
}

#no-docs-text-container {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.sweet-loading-preview {
  height: 100vh !important;
}

.loading-more-container {
  position: absolute;
  bottom: 10vh;
  z-index: 10;
  width: auto;
  height: auto;
  left: 0;
  right: 0;
}