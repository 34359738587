.send-sms-container {
  height: 100vh;
  display: -moz-flex;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: #DADADA;
}

.send-sms-content {
  width: 70%;
  display: -moz-flex;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
}

.send-sms-content-left {
  background: #ffffff;
  height: 700px;
  width: 50%;
  padding: 0 60px;
  text-align: left;
}

.send-sms-content-left-text {
  margin-top: 5rem;
}

.send-sms-content-right {
  background: url(../../assets/images/otp_panel.png) no-repeat center;
  background-size: cover;
  width: 50%;
  height: 700px;
}

.content-bottom{
  position: initial;
  display: -moz-flex;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 1rem;
  left: 0;
  right: 0;
}

.content-header-title {
  font-size: 1.5rem;
  color: #00BF71;
  margin-left: 1rem;
}

.content-middle-1 {
  font-size: 1.1rem;
  margin-top: 2.5rem;
  font-weight: 500;
}

.content-middle-2 {
  font-size: 1.3rem;
  color: #00BF71;
  font-weight: normal;
  font-style: italic;
}

.content-middle-3 {
  font-size: 1rem;
  color: #434343;
  font-family: TTNorms-MediumItalic sans-serif;
  font-style: italic;
}

.content-bottom-text {
  margin-bottom: 0;
  font-size: 0.8rem;
}

.spinner-centered {
  display: block;
  margin: auto;
}

.input-group-text {
  background-color: #C4C4C4 !important;
}

.otp-icon {
  width: 1.5rem;
}

.flex-container {
  display: flex;
  align-items: center;
}

@media (max-width: 1024px) {
  .send-sms-content-left {
    width: 100%;
  }
  .send-sms-content-right {
    display: none;
  }
}

@media (max-width: 1024px) and (orientation: landscape) {
  .send-sms-content-left {
    height: 50%;
  }
  .send-sms-content-left-text {
    margin-top: 2rem;
  }
}

@media (max-width: 850px) and (orientation: landscape) {
  .send-sms-content {
    width: 100%;
  }
  .send-sms-content-left {
    height: 96vh;
    position: absolute;
    bottom: 0;
  }
  .send-sms-content-left-text {
    margin-top: 3rem;
  }
  .content-header-title {
    font-size: 1.2rem;
  }
  .content-middle-1 {
    font-size: 1rem;
    margin-top: 1rem;
  }
  .content-middle-2 {
    font-size: 1.1rem;
  }
  .content-middle-3 {
    font-size: 0.9rem;
  }
  .input-group-text {
    font-size: 0.7rem !important;
  }
  .footer-container {
    display: none;
  }
}

@media (max-width: 768px) and (orientation: landscape) {
  .send-sms-content-left-text {
    margin-top: 3.5rem;
  }
}

@media (max-width: 414px) {
  .send-sms-content {
    width: 100%;
  }
  .send-sms-content-left {
    height: 100vh;
    padding: 0 20px;
  }
  .send-sms-content-left-text {
    margin-top: 6rem;
  }
  .content-header-title {
    font-size: 1.2rem;
  }
  .content-middle-1 {
    font-size: 0.9rem;
    margin-top: 1rem;
  }
  .content-middle-2 {
    font-size: 1rem;
  }
  .content-middle-3 {
    font-size: 0.8rem;
  }
  .input-group-text {
    font-size: 0.7rem !important;
  }
  .action-button-small {
    min-width: 35% !important;
  }
  .content-bottom-text {
    font-size: 0.7rem;
  }
  .footer-text {
    margin-left: 1rem;
  }
}

@media (max-width: 360px) {
  .send-sms-content-left-text {
    margin-top: 5rem;
  }
  .footer-container {
    display: none;
  }
}

@media (max-width: 320px) {
  .reject {
    font-size: 0.6rem;
  }
  .send-sms-content {
    width: 100%;
  }
  .send-sms-content-left {
    height: 100vh;
  }
  .otp-icon {
    width: 1.2rem;
  }
  .content-header-title {
    font-size: 1rem;
  }
  .content-middle-1 {
    font-size: 0.9rem;
    margin-top: 1rem;
  }
  .content-middle-2 {
    font-size: 1rem;
  }
  .content-middle-3 {
    font-size: 0.8rem;
  }
  .input-group-text {
    font-size: 0.7rem !important;
  }
  .margin-top {
    margin-top: 1.5rem;
  }
  .content-bottom-text {
    font-size: 0.6rem;
  }
}