.document-preview-container{
    width: 65%;
    margin: auto;
    padding-top: 3rem;
}
.document-preview-header{
    text-align: left;
    padding-top: 50px;
    padding-bottom: 10px;
}
.document-preview-title{
    font-size: 1.3rem;
    font-weight: 400;
    color: #434343;
}
.document-preview-name{
    font-size: 1.5em;
    color: #434343;
}

.document-preview-subcontainer{
    display: flex;
    justify-content: space-between;
}

.document-preview-count{
    font-weight: 500;
    font-size: 1.5em;
}

.buttons-container{
    position: fixed;
    Width: 100%;
    bottom: 0;
    background-color: rgba(67, 67, 67, 0.8);
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.download-button{
    min-width: 200px !important;
    min-height: 50px;
    border-radius: 4px;
}
.back-button{
    height: 1.7em;
    width: 1.7em;
    margin-top: 15px;
    color: #434343;
    cursor: pointer;
    border-style: solid;
    border-radius: 25px;
    border-width: 2px;
    padding: 3px;
    z-index: 15;
    float: left;
}

.sweet-loading-preview{
    height: 75vh;
    display: flex;
    align-items: center;
}

.pdf-container{
    height: 67vh;
    overflow: hidden;
    overflow-y: scroll;
    border: 1px solid #d0d0d0;
}

.pdf-images{
    max-width: 100%;
    border-width: 0 0 1px 0;
    border-color: #d0d0d0;
    border-style: solid;
}

@media (max-width: 1024px) {
    .pdf-container{
        height: 75vh;
    }
    .document-preview-container{
        width: 90%;
    }
    .download-button {
        min-height: 60px;
    }
}

@media (max-width: 1024px) and (orientation: landscape){
    .pdf-container{
        height: 65vh;
    }
}

@media (max-width: 850px) and (orientation: landscape) {
    .document-preview-header {
        padding-top: 30px;
    }
    .buttons-container {
        height: 20vh;
    }
    .document-preview-title {
        font-size: 1rem;
    }
    .document-preview-name {
        font-size: 1.1em;
    }
    .document-preview-count {
        font-size: 1.1em;
    }
    .download-button {
        min-width: 100px !important;
        min-height: 40px;
    }
}

@media (max-width: 768px) {
    .pdf-container{
        height: 68vh;
    }
}

@media (max-width: 414px) {
    .pdf-container{
        height: 58vh;
    }
    .back-button {
        height: 1.5em;
        width: 1.5em;
    }
    .document-preview-header {
        text-align: justify;
    }
    .document-preview-title{
        font-size: 0.8em;
    }
    .document-preview-name{
        font-size: 0.8em;
    }
    .document-preview-count{
        font-size: 0.8em;
    }
    .download-button {
        min-width: 150px !important;
        min-height: 45px;
    }
}

@media (max-width: 320px) {
    .pdf-container{
        height: 55vh;
    }
    .download-button {
        min-width: 125px !important;
        min-height: 45px;
    }
}


