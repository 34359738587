.loading-container {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #434343;
  background: url(../../assets/images/vidsigner_splashscreen.png) no-repeat center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: -moz-flex;
  display: flex;
  flex-flow: column nowrap;
  -moz-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  align-items: center;
  align-content: center;
}

.loading-text-container {
  position: absolute;
  top: 46%;
  left: 0;
  right: 0;
}

.content-error{
  text-align: center;
  margin-top: 2rem;
}

.error-text{
  font-size: 21px;
  color: #ff5757;
}

.content-process{
  width: 40rem;
  margin: auto;
  margin-top: 7rem;
}

.process-text{
  color: #ffffff;
  display: inline-block;
  margin-left: 10px;
}

.spinner-border{
  color: #00bf71;
}

.bg-success {
  background-color: #00bf71 !important;
}

.welcome-text {
  color: #ffffff;
  font-size: 3rem;
  margin-bottom: 0;
}

@media (max-width: 1024px) {

}

@media (max-width: 850px) and (orientation: landscape) {
  .content-process {
    width: 30rem;
    margin-top: 2rem;
  }
  .welcome-text {
    font-size: 2rem;
  }
}

@media (max-width: 768px) {

}

@media (max-width: 640px) and (orientation: landscape) {
  .welcome-text {
    font-size: 1.5rem;
  }
}

@media (max-width: 414px) {
  .content-process {
    width: 20rem;
  }
  .welcome-text {
    font-size: 2rem;
  }
}

@media (max-width: 375px) {
  .welcome-text {
    font-size: 1.7rem;
  }
}

@media (max-width: 320px) {
  .content-process {
    width: 17rem;
  }
}
