.footer-container {
    width: 100%;
    height: 2rem;
    position: absolute;
    bottom: 0;
    text-align: left;
}

.footer-text {
    font-weight: 500;
    font-size: 0.7rem;
    margin-left: 3rem;
}